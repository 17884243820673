module.exports = [{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-smoothscroll@1.2.0/node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-styled-components@6.11.0_babel-plugin-styled-components@1.13.3_styled-component_y5nzlfvunzagcvov6tpgy7k76a/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":false,"topLevelImportPaths":["@bob/library"],"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-manifest@5.11.0_gatsby@5.13.3_@swc+core@1.3.100_babel-eslint@10.1.0_eslint@7.32_tgb5f7blnmvsrbbawwnzm6khfm/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"LEGO Education","icon":"assets/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7ebf65ec17af49a7523ab1ac29f1c558"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby@5.13.3_@swc+core@1.3.100_babel-eslint@10.1.0_eslint@7.32.0__esbuild@0.18.20_eslint-plu_mbpspfdguhkyjfavuhseizydoq/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
